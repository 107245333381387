import React from "react";
import "./Spinner.css";

const Loading = ({height , width}) => {
  return (
    <div className="spinner-container">
      <div style={{height : height , width : width}} className="spinner"></div>
    </div>
  );
};

export default Loading;
