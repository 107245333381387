import React, { useState } from "react";
import "./Home.css";
import SubmitForm from "../../components/submitForm/SubmitForm";
import Alert from "../../components/alert/Alert";
import OtpInputPopUp from "../../components/otpInputPopUp/OtpInputPopUp";
import Category from "../../components/category/Category";
import DownloadPdf from "../../components/downloadPdf/DownloadPdf";
import Header from "../../components/header/Header";
import DownloadNocPdf from "../../components/downloadNocPdf/DownloadNocPdf";
/**
 * @author Rajesh
 * @description Home screen contains public customer query form as per selection
 * @returns Home Screen
 */
const Home = () => {

  const [open, setOpen] = useState(false);
  const [categoryScreen, setCategoryScreen] = useState(false);
  const [model1, setModel1] = useState(false);
  const [model2, setModel2] = useState(false);
  const [model3, setModel3] = useState(false);


  const [otpCount, setOtpCount] = useState(0);
  const [loanId, setLoanId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [pan, setPan] = useState("");
  const [requestId, setRequestId] = useState("");
  const [soaStatus, setSoaStatus] = useState(null);
  const [soaRequestId, setSoaRequestId] = useState(null);
  const [soaRemark, setSoaRemark] = useState(null);
  const [soaFileUrl, setSoaFileUrl] = useState(null);
  const [soaRequestedDate, setSoaRequestedDate] = useState(null);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [fullName,setFullName]= useState("");
  const [loginUser,setLoginUser]= useState(false);
  const [nocFileUrl,setNocFileUrl] = useState(null);
  

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <>
      <Header 
      loginUser={loginUser} 
      fullName={fullName} 
      setModel1={setModel1} 
      setModel2={setModel2} 
      setModel3={setModel3}
      setCategoryScreen={setCategoryScreen} 
      setLoginUser={setLoginUser}
       />

      <div className={`home-screen`}>
        {!categoryScreen ?
          <div className="main-container-1" >
            {open && (
              <Alert
                type={alertType}
                message={alertMessage}
                handleClose={handleClose}
              />
            )}
            <div className={`background-content-1`} >
              <h1 className="home-page-title">Enter Your Details</h1>
              <SubmitForm
                open={open}
                setOpen={setOpen}
                setAlertType={setAlertType}
                setAlertMessage={setAlertMessage}
                setModel2={setModel2}
                otpCount={otpCount}
                setOtpCount={setOtpCount}
                loanId={loanId}
                setLoanId={setLoanId}
                mobileNumber={mobileNumber}
                setMobileNumber={setMobileNumber}
                pan={pan}
                setPan={setPan}
                setRequestId={setRequestId}
                fullName={fullName}
                setFullName={setFullName}
              />
            </div>
            {model2 && (<OtpInputPopUp
              setOpen={setOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setModel2={setModel2}
              length={6}
              setCategoryScreen={setCategoryScreen}
              loanId={loanId}
              setLoanId={setLoanId}
              mobileNumber={mobileNumber}
              setMobileNumber={setMobileNumber}
              pan={pan}
              setPan={setPan}
              otpCount={otpCount}
              setOtpCount={setOtpCount}
              setRequestId={setRequestId}
              requestId={requestId}
              fullName={fullName}
              setFullName={setFullName}
              setLoginUser={setLoginUser}
            />)}
          </div> :

          <div className="main-container">
            {open && (
              <Alert
                handleClose={handleClose}
                type={alertType}
                message={alertMessage}
              />
            )}
            <Category
              setModel1={setModel1}
              setModel3={setModel3}
              loanId={loanId}
              setSoaStatus={setSoaStatus}
              setSoaRequestId={setSoaRequestId}
              setSoaRemark={setSoaRemark}
              setSoaFileUrl={setSoaFileUrl}
              setSoaRequestedDate={setSoaRequestedDate}
              setOpen={setOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              fullName = {fullName}
              setNocFileUrl={setNocFileUrl}
            />
            {model1 && (<DownloadPdf
              setModel1={setModel1}
              loanId={loanId}
              soaStatus={soaStatus}
              setSoaStatus={setSoaStatus}
              soaRemark={soaRemark}
              setSoaRemark={setSoaRemark}
              soaRequestId={soaRequestId}
              setSoaRequestId={setSoaRequestId}
              soaFileUrl={soaFileUrl}
              setSoaFileUrl={setSoaFileUrl}
              soaRequestedDate={soaRequestedDate}
              setSoaRequestedDate={setSoaRequestedDate}
              setOpen={setOpen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setCategoryScreen={setCategoryScreen}
              setModel2={setModel2}
              setLoginUser={setLoginUser}
            />)}
            {model3 && (<DownloadNocPdf
              setModel3={setModel3}
              loanId={loanId}
              nocFileUrl={nocFileUrl}
              setNocFileUrl={setNocFileUrl}
              setOpen={setOpen}
              setCategoryScreen={setCategoryScreen}
              setAlertType={setAlertType}
              setAlertMessage={setAlertMessage}
              setLoginUser={setLoginUser}
            />)}
          </div>
        }
      </div>
    </>
  );
};

export default Home;
