import React from "react";
import "./DownloadNocPdf.css";
import downloadImage from "../../resources/images/file.png";

const DownloadNocPdf = ({
  setModel3,
  nocFileUrl,
  setNocFileUrl,
}) => {
  // Function to handle file download
  const handleDownload = () => {
    if (nocFileUrl) {
      const link = document.createElement("a");
      link.href = nocFileUrl;
      link.setAttribute("download", "noc.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Function to handle modal close
  const handleCrossButton = () => {
    setModel3(false);
    setNocFileUrl(null);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div>
          <img
            src={downloadImage}
            alt="download-icon"
            className="download-icon"
          />
        </div>
        {nocFileUrl ? (
          <>
            <p className="pdf-statement">Your NOC file is ready to Download!</p>
            <button onClick={handleDownload} className="download-btn">
              Download
            </button>
          </>
        ) : (
          <p className="pdf-statement failure">NOC File not found.</p>
        )}
        <span onClick={handleCrossButton} className="cross">X</span>
      </div>
    </div>
  );
};

export default DownloadNocPdf;
