import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import axios from "axios";
import PaymentSuccess from "../paymentModal/PaymentSuccess";
import PaymentError from "../paymentModal/PaymentError";
import "./QRCodeGenerator.css";

const QRCodeGenerator = ({
  loanId,
  isOpen,
  onClose,
  loanData,
  setDataSuccess,
  fullName,
  setTimeLeft,
  timeLeft,
}) => {
  const [loading, setLoading] = useState(false);
  const [upiLink, setUpiLink] = useState(""); // Store generated UPI link
  const [viewState, setViewState] = useState("QR"); // States: 'QR', 'Success', 'Error'
  const amountToShow = loanData?.repaymentDetails?.amount || "0";

  /**
   * useEffect to generate a UPI payment link when loanData changes.
   * Function: generateUpiLink
   * - Generates a UPI payment URL with the following parameters:
   *   - pa: Payee address (e.g., UPI ID).
   *   - pn: Payee name (defaults to "Customer Name" if not provided).
   *   - tr: Transaction reference ID (last 12 characters of loan ID or defaultRefID).
   *   - am: Amount to pay (defaults to "0" if not provided).
   *   - cu: Currency (INR).
   *   - mc: Merchant code (6012 for loan repayment).
   *
   * On change of loanData:
   * - Generates the UPI link using loan_id, fullName, and amountToShow.
   * - Updates the state with the generated UPI link using setUpiLink.
   */
  useEffect(() => {
    const generateUpiLink = (loanId, fullName, amountToShow) => {
      const referenceId = loanId ? loanId.slice(-12) : "defaultRefID";
      return `upi://pay?pa=mam.123456@icici&pn=${encodeURIComponent(
        fullName || "Customer Name"
      )}&tr=${referenceId}&am=${amountToShow || "0"}&cu=INR&mc=6012`;
    };

    const link = generateUpiLink(loanData?.loan_id, fullName, amountToShow);

    setUpiLink(link);
  }, [loanData]);

  // * Function to fetch repayment details.
  // * This function makes an API call to retrieve the transaction history
  // * for a specific loan ID and amount, handles the response, and updates
  // * the UI accordingly.

  const repaymentDetails = async () => {
    try {
      setLoading(true); // Start the loading state to indicate the API call is in progress.

      // Make a POST request to the repayment details API endpoint.
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/repayment-details/${loanId}/${amountToShow}`,
        {
          headers: {
            "Content-Type": "application/json", // Set the request header to JSON format.
          },
        }
      );

      // Check if the API response status is 200 and the returned data indicates success.
      if (response?.status === 200 && response?.data?.status === "success") {
        setViewState("Success"); // Update the view state to "Success" if the transaction is successful.
        setDataSuccess(true); // Indicate that the transaction data was processed successfully.
      } else {
        // If the API response does not indicate success, throw an error to handle it in the catch block.
        throw new Error("API did not return success status.");
      }
    } catch (error) {
        setViewState("Error");
        setDataSuccess(false); // Indicate that the transaction data could not be processed.
    } finally {
      setLoading(false); // End the loading state regardless of success or failure.
    }
  };

  // Start the API call when modal opens
  useEffect(() => {
    if (isOpen) {
      setViewState("QR");
      setTimeLeft(300);
      repaymentDetails();
    }
  }, [isOpen]);

  // Handle countdown timer
  useEffect(() => {
    let countdownTimer;

    if (timeLeft > 0) {
      countdownTimer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setViewState("Error");
    }

    return () => {
      clearInterval(countdownTimer);
    };
  }, [timeLeft]);

  const formatTime = (time) => {
    // Check if the input time is invalid (NaN or negative)
    // If so, return the default formatted time "00:00"
    if (isNaN(time) || time < 0) return "00:00";

    // Calculate the number of whole minutes from the total time
    const minutes = Math.floor(time / 60);

    // Calculate the remaining seconds after extracting minutes
    const seconds = time % 60;

    // Format the minutes and seconds as two-digit values (e.g., "09:05")
    // Add a leading zero if the value is less than 10
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      {viewState === "QR" && (
        <div className="modal-content">
          <h2>Scan QR Code</h2>
          <QRCode value={upiLink} />
          <div className="payable-amount">
            <p>Payable Amount</p>
            <h3>₹{loanData?.repaymentDetails?.amount || "0"}</h3>
          </div>
          <p className="loading-Qr">
            Please wait while we complete the transaction. This may take up to 5
            minutes. Do not refresh the page.
          </p>
          {loading && (
            <div className="loading-Qr">
              <p>fetching the response.....</p>
              <p>Time left: {formatTime(timeLeft)}</p>
            </div>
          )}
        </div>
      )}

      {viewState === "Success" && (
        <PaymentSuccess
          onBack={() => {
            setViewState("QR"); // Reset to QR Code View
            onClose(); // Close Parent Modal
          }}
        />
      )}

      {viewState === "Error" && (
        <PaymentError
          onBack={() => {
            setViewState("QR"); // Reset to QR Code View
            onClose(); // Close Parent Modal
          }}
        />
      )}
    </div>
  );
};

export default QRCodeGenerator;
