import React from "react";
import { useState, useEffect } from "react";
import "./SubmitForm.css";
import axios from "axios";
import Spinner from "../Loading/Loading";

/**
 * @author Rajesh
 * @description submit form with loanId, mobileNumber and UMRN (max amoung and dates if nach selection options is amend)
 * @param {Object} Object  { natchSelected, setOpen, setAlertType, setAlertMessage}
 * @returns submit form
 */
const SubmitForm = ({
  setOpen,
  setModel2,
  otpCount,
  setOtpCount,
  loanId,
  setLoanId,
  mobileNumber,
  setMobileNumber,
  pan,
  setPan,
  setRequestId,
  fullName,
  setFullName,
  setAlertType,
  setAlertMessage,
}) => {
  const [loading, setLoading] = useState(false); // State to manage spinner visibility

  useEffect(() => {
    setLoanId("");
    setMobileNumber("");
    setPan("");
  }, []);

  const checkPayload = (payload) => {
    //Check for missing Fields

    let payloadError = false;

    let { loan_id, mob_num, pan } = payload;

    loan_id = loan_id.trim();
    mob_num = mob_num.trim();
    pan = pan.trim();

    if (loan_id.length === 0 || /\s/.test(loan_id)) {
      payloadError = true;
    } else if (!/^[6-9][0-9]{9}$/.test(mob_num)) {
      payloadError = true;
    } else if (
      pan.length === 0 ||
      !/^[A-Z]{3}[PCHABGJFLT]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/.test(pan)
    ) {
      payloadError = true;
    }
    return payloadError;
  };

  const capitalizeWords = (str) =>
    str
      ? str
          .trim()
          .split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ")
      : "";

  // Function to extract and format the full name from API response data
  const getFullNameFromResponse = (data) => {
    const {
      coappName = "",
      first_name: firstName = "",
      middle_name: middleName = "",
      last_name: lastName = "",
      otp_response: { requestId } = {},
    } = data || {};

    const fullName = [
      capitalizeWords(coappName),
      capitalizeWords(firstName),
      capitalizeWords(middleName),
      capitalizeWords(lastName),
    ]
      .filter(Boolean) // Remove empty strings
      .join(" "); // Join with a single space

    return { fullName, requestId: requestId || "" };
  };

  // Handle form submission for sending OTP
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Prepare the payload with loan details (loan ID, mobile number, and PAN)
    const payload = {
      loan_id: loanId,
      mob_num: mobileNumber,
      pan: pan,
    };

    // Check if any required field is missing using a validation function
    const payloadError = checkPayload(payload);

    // If any field is missing, show an alert to prompt the user to provide all details
    if (!payload.loan_id || !payload.mob_num || !payload.pan) {
      setOpen(true);
      setAlertType("failure");
      setAlertMessage("Kindly Provide all details");
      return;
    }

    // If the payload has errors, show an error alert with a relevant message
    if (payloadError) {
      setOpen(true);
      setAlertType("failure");
      setAlertMessage("Incorrect details: please provide correct details");
      return;
    }

    // If no errors and OTP count is less than 3, proceed with the API call
    if (!payloadError) {
      if (otpCount >= 3) {
        // If OTP request count exceeds 3, show an error message and exit
        setOpen(true);
        setAlertType("failure");
        setAlertMessage("Please try again later");
        return;
      }
      setLoading(true); // Show loading spinner while waiting for the API response

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        // Make an API call to send OTP
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/send_otp`,
          payload,
          config
        );
        // If the API responds with a success status
        if (response?.data?.status === "success") {
          // Use the function to get full name and requestId
          const { fullName, requestId } = getFullNameFromResponse(response?.data?.data);
        
          // Update state with extracted and formatted data
          setFullName(fullName);
          setRequestId(requestId);
        
          setOtpCount((prevCount) => prevCount + 1); // Safely increment OTP count
          setModel2(true); // Show the next modal after successful OTP request
          setLoading(false); // Hide loading spinner after the API response is received
        }

        // If the API responds with an error status, show an error message
        if (response?.data?.status === "error") {
          setOpen(true);
          setAlertType("failure");
          setAlertMessage("Incorrect details: please provide correct details");
        }
      } catch (error) {
        // If there is an error with the API call, show an error message
        setOpen(true);
        setAlertType("failure");
        setAlertMessage(
          error.response.data.message ??
            "Incorrect details: please provide correct details"
        );
        setLoading(false); // Hide the loading spinner if an error occurs
      }
    } else {
      // If the payload is invalid (missing required details), show an alert message
      setOpen(true);
      setAlertType("failure");
      setAlertMessage("Kindly Provide all details");
      setLoading(false); // Hide the loading spinner
    }
  };

  return (
    <div className="form-container">
      <form action="" className="form">
        <div className="form-upper">
          <div className="form-input">
            {loanId && <label for="loanId" >Loan ID</label>}
            <input
              id="loanId"
              type="text"
              placeholder="Loan ID"
              value={loanId}
              onChange={(e) => {
                setLoanId(e.target.value);
              }}
              style={{
                width: "100%",
              }}
              required
              autoComplete="off"
            />
          </div>

          <div className="form-input">
            {mobileNumber && <label for="mobileNumber" >Mobile Number</label>}
            <input
              id="mobileNumber"
              type="tel"
              placeholder="Mobile Number"
              maxLength="10"
              pattern="[1-9][0-9]{9}"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
              required
              autoComplete="off"
            />
          </div>

          <div className="form-input">
            {pan && <label for="pan" >PAN</label>}
            <input
              id="pan"
              type="text"
              placeholder="PAN"
              value={pan}
              onChange={(e) => {
                setPan(e.target.value);
              }}
              required
              autoComplete="off"
            />
          </div>
        </div>
        <button type="submit" className="submit-button" onClick={handleSubmit}>
          {loading ? (
            <span
            style={{display:"flex",alignItems:"center" , justifyContent:"center"}}  
            >
              Processing&nbsp;
              <Spinner height={"25px"} width={"25px"} />  
            </span>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
};

export default SubmitForm;
