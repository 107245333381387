import React from "react";
import "./PaymentStyles.css"; 

const PaymentError = ({ onBack }) => {
  return (
    <div className="result-container">
      <div className="result-icon error">
        <i className="crossmark">✗</i>
      </div>
      <h1>Payment Error</h1>
      <p>There was an issue processing your payment. Please try again.</p>
      <button className="action-button" onClick={onBack}>
        Go back
      </button>
    </div>
  );
};

export default PaymentError;
