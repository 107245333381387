import React, { useEffect, useState } from 'react';
import "./DownloadPdf.css";
import axios from 'axios';
import downloadImage from "../../resources/images/file.png";

const DownloadPdf = ({
    setModel1,
    loanId,
    soaStatus,
    setSoaStatus,
    soaRemark,
    setSoaRemark,
    soaRequestId,
    setSoaRequestId,
    soaFileUrl,
    setSoaFileUrl,
    soaRequestedDate,
    setSoaRequestedDate,
    setOpen,
    setCategoryScreen,
    setModel2,
    setAlertType,
    setAlertMessage,
    setLoginUser


}) => {

    const [dateInRange, setDateInRange] = useState(null);
    const [generateButton, setGenerateButton] = useState(true);
    const [generateMessage, setGenerateMessage] = useState("Please click the button below to generate your Statement of Account (SOA)");
    const [generateMessageChange, setGenerateMessageChange] = useState(false);


    useEffect(() => {
        checkDate();
    }, [])


    // * Function to check if the given SOA request date is within a specific time range (in hours).
    // * It compares the current date and time with the given SOA requested date
    // * and calculates the time difference to determine whether the date is in range.
    const checkDate = () => {
        if (!soaRequestedDate) {
            setDateInRange(false);
            return;
        }
        const currentDate = new Date();
        const givenDate = new Date(soaRequestedDate);
        const timeDifference = Math.abs(currentDate - givenDate);
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
        if (hoursDifference > Number(process.env.REACT_APP_NUMBER_OF_HOURS)) {
            setDateInRange(false);
        } else {
            setDateInRange(true);
        }
    }

    // * Function to download a Statement of Account (SOA) PDF file from the server.
    // * It sends a POST request to the API, retrieves the file in Base64 format, 
    // * converts it to a downloadable PDF, and handles any errors during the process.
    const downloadFile = async () => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/soa-request/download/${loanId}/${soaRequestId}`, {}, {
                headers: {
                    'Content-Disposition': 'attachment; filename="soa-file.pdf"',
                    'Content-Type': 'application/pdf',
                }
            });
            if (response?.data?.status === "success") {
                const base64Data = response.data.data;

                const base64StringWithPrefix = `data:application/pdf;base64,${base64Data}`;

                const link = document.createElement('a');
                link.href = base64StringWithPrefix;
                link.setAttribute('download', 'soa-report.pdf');
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                
            }
            if (response?.data?.status === "error") {
                setAlertType("failure");
                setAlertMessage("something went wrong");
                setOpen(true);
                setTimeout(() => {
                    setModel1(false);
                    setCategoryScreen(false);
                    setModel2(false);
                    setLoginUser(false);
                }, 3000);

            }
        } catch (error) {
            setAlertType("failure");
            setAlertMessage(error?.response?.data?.message);
            setOpen(true);
            setTimeout(() => {
                setModel1(false);
                setCategoryScreen(false);
                setModel2(false);
                setLoginUser(false);
            }, 3000);
        }
    }

    // * Function to generate a report (SOA - Statement of Account) by sending a POST request to the API.
    // * It handles both success and error responses and updates the respective UI states accordingly.
    const generateReport = async () => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/soa-request/generate/${loanId}`);
            if (response?.data?.status === "success") {
                setGenerateMessage("The request has been successfully created");
                setGenerateMessageChange(true);
                setGenerateButton(false);
            }
            if (response?.data?.status === "error") {
                setAlertType("failure");
                setAlertMessage("something went wrong");
                setOpen(true);
                setTimeout(() => {
                    setModel1(false);
                    setCategoryScreen(false);
                    setModel2(false);
                    setLoginUser(false);
                }, 3000);

            }
        } catch (error) {
            setAlertType("failure");
            setAlertMessage(error?.response?.data?.message);
            setOpen(true);
            setTimeout(() => {
                setModel1(false);
                setCategoryScreen(false);
                setModel2(false);
                setLoginUser(false);
            }, 3000);
        }

    }

    const setAllStatesToNull = () => {
        setSoaStatus(null);
        setSoaFileUrl(null);
        setSoaRemark(null);
        setSoaRequestId(null);
        setSoaRequestedDate(null);
        setDateInRange(null);
        setModel1(false)
    }

    const handleCrossButton = () => {
        setAllStatesToNull();
    }

    return (
        <div className='modal-overlay'>
            <div className='modal-content'>

                {
                    soaStatus != "0" && (
                        <div ><img src={downloadImage} alt="download-image" className='download-icon' /></div>
                    )
                }
                < p className='pdf-statement'>{((soaStatus === "1" || !soaStatus) && !dateInRange) ? generateMessage : (soaStatus === "0" || (dateInRange && !soaRemark && !soaFileUrl)) ? "Request is under progress, please try after sometime" : (soaStatus === "1" && dateInRange && soaRemark) ? "There is a technical error, please try again after 24 hours" : ""} </p>

                {(soaStatus === "1" && dateInRange && soaFileUrl) && <div>
                    <p className='message-1'>Your Statement of Account (SOA) is ready for download!</p>
                    <p className='message-2'>If you make any transactions, please note that the latest information will be reflected in your SOA within 2-3 working days</p>
                </div>}


                {((!dateInRange && !generateMessageChange) || !soaStatus) && generateButton && <button onClick={generateReport} className='download-btn'>Generate</button>}

                {(soaStatus === "1" && dateInRange && soaFileUrl) && <button onClick={downloadFile} className='download-btn'>Download</button>}

                <span onClick={handleCrossButton} className='cross'>X</span>
            </div>

        </div >
    )
}

export default DownloadPdf
